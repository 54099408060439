export default [
    {
        id: 'name',
        label: '小程序名称',
        formType: 'input',
    },
    {
        id: 'adminPhone',
        label: '管理员手机号',
        formType: 'input',
        options: [{ value: 1, label: '个人' }, { value: 2, label: '个体户' }, { value: 3, label: '企业' }]
    },
    {
        id: 'adminWechat',
        label: '管理员微信号',
        formType: 'input',
    },
    {
        id: 'type',
        label: '小程序类型',
        formType: 'radio',
        options: [{ value: 1, label: '个人' }, { value: 2, label: '个体户' }, { value: 3, label: '企业' }]
    },
    {
        id: 'status',
        label: '小程序状态',
        formType: 'radio',
        options: [{ value: 1, label: '使用中' }, { value: 2, label: '停用' }]
    }
]