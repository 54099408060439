/**
 * 获取url参数
 * @param {*} key 参数key
 * @param {*} urlStr 指定地址
 * @returns 参数key的value
 */
export default (key, urlStr) => {
  const search = urlStr ? new URL(urlStr).search : window.location.search;
  const params = new URLSearchParams(search);
  const queryObject = {};

  for (const [key, value] of params) {
    // 重复的值，直接覆盖
    queryObject[key] = value;
    // 重复的值，返回数组
    // if (queryObject[key]) {
    //   queryObject[key] = [].concat(queryObject[key], value);
    // } else {
    //   queryObject[key] = value;
    // }
  }

  return key ? queryObject[key] : queryObject;
}
