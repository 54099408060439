import React, { useState, useEffect } from 'react';
import { Button, Modal, Image } from 'antd';
import { TableColAction, TableWrap, TableHead } from './../../common/Table';
import SearchWrap from './../../common/SearchWrap';
import EditDrawer from './EditDrawer';
import './style.scss';

import searchConfig from './searchConfig';
import columns from './columns';

const ConfigList = ({
    FetchApi,
    statusList = [],
    adminList = [],
}) => {

    // 新增或编辑面板的显示状态
    const [visible, setVisible] = useState(false);
    // 编辑暂存数据
    const [editRecord, setEditRecord] = useState({});
    // 表格数据
    const [tableInfo, setTableInfo] = useState({
        dataList: [],
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    });

    // 获取合作商列表
    const editConfigInfo = async (payload) => {
        const merchantId = payload?.merchantId;
        const reslut = await FetchApi(
            merchantId ? `put:merchant/${merchantId}` : 'post:merchant',
            payload,
            merchantId ? '修改成功' : '新增成功');

        if (reslut.success) {
            await getConfigList({
                pageNumber: 1,
                pageSize: 10,
            });
            setVisible(false);
        }
    }

    // 获取合作商列表
    const getConfigList = async (payload) => {
        const reslut = await FetchApi('get:merchant', {
            pageNumber: tableInfo.pageNumber,
            pageSize: tableInfo.pageSize,
            ...payload
        }, '加载成功');
        setTableInfo({
            dataList: reslut?.merchantInfoList || [],
            total: reslut?.total || 0,
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize,
        })
    }

    // 初始化页面
    useEffect(() => {
        getConfigList({ pageSize: 10, pageNumber: 1 });
    }, [])

    // 打开新增或编辑面板
    const onEditFn = (record) => {
        setVisible(true);
        setEditRecord(record);
    }

    // 查看详情
    const onDetailFn = (record) => {
        console.log('查看', record)
    }

    // 分页变动，重新获取数据
    const onPaginationChange = (record) => {
        getConfigList({ pageSize: record.pageSize, pageNumber: record.pageNumber });
    }

    const onSearch = (params) => {
        getConfigList({ pageSize: 10, pageNumber: 1, ...params });
    }
    const onReset = () => {
        getConfigList({ pageSize: 10, pageNumber: 1 });
    }

    const onDetailClick = (payload) => {
        window.location.hash = `/config-detail/${payload.merchantId}`;
    }

    return <>
        {/* 搜索组件 */}
        <SearchWrap config={searchConfig({ statusList, adminList })} onSearch={onSearch} onReset={onReset} />

        {/* 表头 */}
        <TableHead>
            <Button type="primary" onClick={() => onEditFn({})}>新增</Button>
        </TableHead>

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo.dataList}
            columns={columns({  })}
            total={tableInfo.total}
            pageNumber={tableInfo.pageNumber}
            pageSize={tableInfo.pageSize}
            onPaginationChange={onPaginationChange}
            lastColWidth={120}
            scroll={{ x: '100%' }}
        >
            <TableColAction text='编辑' onClick={(record) => onEditFn(record)} />
        </TableWrap>

        {/* 编辑面板 */}
        <EditDrawer
            visible={visible}
            editRecord={editRecord}
            onClose={() => setVisible(false)}
            statusList={statusList}
            adminList={adminList}
            onOk={editConfigInfo}
        />
    </>
}

ConfigList.defaultProps = {
    statusList: [
        { label: '待签约', value: 'Init' },
        { label: '配置中', value: 'Waiting' },
        { label: '合作中', value: 'Valid' },
        { label: '已解约', value: 'Invalid' }
    ],
    adminList: [
        { label: '超管', value: 'SuperAdmin', disabled: true },
        { label: '管理员', value: 'Admin' },
        { label: '合作商', value: 'Partner' }
    ]
}

export default ConfigList;