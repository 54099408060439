import React, { useState, useEffect } from 'react';
import './style.scss';

const UserCenter = ({
    FetchApi
}) => {


    const [miniAppInfo, setMiniAppInfo] = useState({});

    const getMiniAppInfo = async () => {

        const result = await FetchApi('get:wechatMiniProgram/getByShop');

        setMiniAppInfo(result?.info || {});
    }

    const onDownloadQrcode = () => {
        window.location.href = miniAppInfo.appOssUrl;
    }


    useEffect(() => {
        getMiniAppInfo();
    }, [])


    console.log('miniAppInfo', miniAppInfo);

    return <div className='user-center-container'>

        <div className='miniapp-qrcode-wrap' onClick={onDownloadQrcode}>
            <img className='miniapp-qrcode' src={miniAppInfo.appOssUrl || ''} />
        </div>


        <div className='miniapp-info-wrap'>

            <div className='miniapp-info-row'>
                <div className='info-row-key'>小程序AppId：</div>
                <div className='info-row-val'>{miniAppInfo.appId || '-'}</div>
            </div>

            <div className='miniapp-info-row'>
                <div className='info-row-key'>小程序名称：</div>
                <div className='info-row-val'>{miniAppInfo.name || '-'}</div>
            </div>

            <div className='miniapp-info-row'>
                <div className='info-row-key'>管理员手机号：</div>
                <div className='info-row-val'>{miniAppInfo.adminPhone || '-'}</div>
            </div>

            <div className='miniapp-info-row'>
                <div className='info-row-key'>管理员微信号：</div>
                <div className='info-row-val'>{miniAppInfo.adminWechat || '-'}</div>
            </div>

            <div className='miniapp-info-row'>
                <div className='info-row-key'>小程序状态：</div>
                <div className='info-row-val'>{miniAppInfo.status || '-'}</div>
            </div>


        </div>



    </div>
}

export default UserCenter;