const genderMap = {
    0: '未知',
    1: '男',
    2: '女'
}

const platformMap = {
    WeChat: '微信',
    AliPay: '支付宝',
    undefined: '其他',
}
export default [
    {
        title: '头像',
        dataIndex: 'avatarUrl',
        width: 90,
        render: val => <img src={val} style={{ width: 60, height: 60, borderRadius: 12, border: '1px solid #ddd' }} />
    },
    {
        title: 'ID',
        dataIndex: 'userId',
        width: 200,
    },
    {
        title: '姓名',
        dataIndex: 'userName',
        width: 120,
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        width: 130,
    },
    {
        title: '性别',
        dataIndex: 'gender',
        width: 60,
        render: val => genderMap[val] || '--'
    },
    {
        title: '车牌号',
        dataIndex: 'licensePlateNumber',
        width: 140,
        render: (val, record) => val ? <span className={`license-plate-node ${record.licensePlateType == 'electric' && 'electric'}`}>{String(val).slice(0, 2)}·{String(val).slice(2)}</span> : '--'
    },
    {
        title: '生日',
        dataIndex: 'birthday',
        width: 120,
        render: val => val || '--'
    },
    {
        title: '剩余次数',
        dataIndex: 'frequency',
        width: 90,
    },
    {
        title: '平台类型',
        dataIndex: 'platform',
        width: 100,
        render: val => platformMap[val] || '--'
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        width: 120,
    },
    {
        title: '编辑时间',
        dataIndex: 'gmtModify',
        width: 120,
    },
    {
        title: '备注',
        dataIndex: 'remark',
        width: 150,
    },

]