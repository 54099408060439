import React from 'react';
import { Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../common/Layout/Header';
import Content from '../common/Layout/Content';
import StatusPage from './StatusPage';

const PageWrap = ({
    ReactNode,
    menuList = [],
    menuList1 = [],
    loginInfo = {},
}) => {

    // 未登录跳转至登录页面
    if (!loginInfo?.phone) {
        return <Navigate to="/login" replace />
    }

    return (<Layout className='console-layout-container'>
        <Header loginInfo={loginInfo} />
        <Content menuList={menuList.filter(i => i.roleType.includes(loginInfo.roleType))}>
            {
                loginInfo?.status === 'Valid' ? ReactNode : <StatusPage />
            }
        </Content>
    </Layout>);
};

PageWrap.defaultProps = {
    menuList: [
        {
            key: '/miniapp-list',
            icon: <i className='iconfont icon-tiny-app' />,
            roleType: ['Admin'],
            label: '小程序列表',
        },
        {
            key: 'member-card',
            icon: <i className='iconfont icon-member-card' />,
            label: '卡模板',
            roleType: ['Admin', 'Partner'],
        },
        // {
        //     key: 'goods-lists',
        //     icon: <i className='iconfont icon-production' />,
        //     label: '商品列表',
        // },
        {
            key: 'member-list',
            icon: <i className='iconfont icon-member' />,
            label: '会员列表',
            roleType: ['Admin', 'Partner'],
        }, {
            key: 'config-list',
            icon: <i className='iconfont icon-config' />,
            label: '合作商列表',
            roleType: ['Admin'],
        }
    ],

    menuList1: [
        {
            key: 'member-card',
            icon: <i className='iconfont icon-member-card' />,
            label: '卡模板',
        },
        {
            key: 'member-list',
            icon: <i className='iconfont icon-member' />,
            label: '会员列表',
        }
    ]

}

export default PageWrap;