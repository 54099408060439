import React, { useEffect } from 'react';
import { Form, Select, Input, Drawer, Button } from 'antd';


const EditModal = ({
    visible,
    editRecord = {},
    FetchApi,
    onOk = () => { },
    onClose = () => { },
    getCardsList = () => { }
}) => {

    // form 实例
    const [form] = Form.useForm();

    useEffect(() => {
        if (editRecord.templateId) {
            form.setFieldsValue(editRecord);
        } else {
            form.resetFields();
        }
    }, [editRecord])



    const onOkFn = () => {

        form.validateFields().then(vals => {
            console.log('vals', vals);
            onSubmit(vals);
        }).catch((errorInfo) => {
            console.log('errorInfo', errorInfo);
        });
    }

    const onSubmit = async (payload) => {
        const isEdit = Object.keys(editRecord).length;
        const reslut = await FetchApi(
            isEdit ? `put:cardTemplate/${editRecord.templateId}` : 'post:cardTemplate',
            payload,
            isEdit ? '编辑成功' : '新增成功');

        if (reslut.success) {
            console.error('新增成功')
            onClose();
            getCardsList({ pageSize: 10, pageNumber: 1 });
        }
    }


    return (<Drawer
        title={Object.keys(editRecord).length ? "编辑卡模板" : '新增卡模板'}
        onClose={onClose}
        open={visible}
        width={'40%'}
        footer={<>
            <Button onClick={onOkFn} type='primary'>确定</Button>
            <Button onClick={onClose} style={{ marginLeft: 24 }}>取消</Button>
        </>}
    >
        <Form
            form={form}
            labelAlign="right"
            labelCol={{ flex: '80px' }}
        >
            {
                editRecord.cardId && <Form.Item
                    label="模板ID"
                    name="cardId"
                    required
                >
                    <Input disabled />
                </Form.Item>
            }
            <Form.Item
                label="模板名称"
                name="templateName"
                required
                rules={[{ required: true, message: '请输入卡模板名称' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="售价"
                name="amount"
                required
                rules={[{ required: true, message: '请填写卡模板售价' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="消费次数"
                name="frequency"
                required
                rules={[{ required: true, message: '请填写消费次数' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="状态"
                name="status"
                required
                rules={[{ required: true, message: '请选择状态' }]}
            >
                <Select
                    options={[
                        { label: '生效中', value: 'Valid' },
                        { label: '已失效', value: 'Invalid' },
                        { label: '待生效', value: 'Init' }
                    ]}
                />
            </Form.Item>
            <Form.Item label="备注" name="extInfo">
                <Input />
            </Form.Item>
        </Form>
    </Drawer>)
}

export default EditModal;