import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Input, Radio, Select } from "antd";

const EditModal = ({
    visible = true,
    onClose = () => { },
    editRecord = {},
    onOk = () => { },
    FetchApi
}) => {

    // form 实例
    const [form] = Form.useForm();
    // 商家列表
    const [merchantList, setMerchantList] = useState([]);
    // 店铺列表
    const [shopListList, setShopListList] = useState([]);

    /**
     * 获取商家列表
    */
    const getMerchantList = async () => {
        const reslut = await FetchApi('get:merchant', {
            pageNumber: 1,
            pageSize: 100,
        });

        const tempList = (reslut?.merchantInfoList || []).map(item => ({
            ...item,
            value: item.merchantId,
            label: item.brandName,
            disabled: item.status !== 'Waiting',
        }));
        setMerchantList(tempList);
    }

    /**
     * 店铺列表
    */
    const getShopListByMerchantId = async (merchantId) => {
        const reslut = await FetchApi(`get:merchant/${merchantId}`, {
            pageNumber: 1,
            pageSize: 100,
        });
        const tempList = (reslut?.merchantInfo?.shopInfoList || []).map(item => ({
            ...item,
            value: item.shopId,
            label: item.shopName || item.shopId,
        }))

        form.setFieldsValue({ shopId: tempList?.[0]?.value });

        setShopListList(tempList);

    }

    /**
     * 异步交互
    */
    useEffect(() => {
        getMerchantList();
        if (editRecord.appId) {
            form.setFieldsValue(editRecord);
        } else {
            form.resetFields();
        }
    }, [editRecord]);

    // 提交表单
    const onOkFn = async () => {
        const vals = await form.validateFields();
        onClose();
        onOk(vals);
    }

    // 选择商家
    const onMerchantChange = (val, record) => {
        form.setFieldsValue({
            merchantId: val,
            binderName: record.name,
            binderIdcard: record.idcard,
            email: record.email,
        })
        getShopListByMerchantId(val);
    }

    // 选择店铺
    const onShopChange = () => {

    }

    return (<Drawer
        title={editRecord.appId ? '编辑' : '新增'}
        onClose={onClose}
        open={visible}
        width={'40%'}
        footer={<>
            <Button onClick={onOkFn} type='primary'>确定</Button>
            <Button onClick={onClose} style={{ marginLeft: 24 }}>取消</Button>
        </>}
    >
        <Form form={form} labelAlign="right" labelCol={{ flex: '110px' }}>

            <Form.Item label="数据主键ID" name="miniProgramId" hidden>
                <Input disabled />
            </Form.Item>

            <Form.Item label="关联商家" name="merchantId" rules={[{ required: true, message: '请选择小程序关联的商家' }]}>
                <Select onChange={onMerchantChange} options={merchantList} />
            </Form.Item>

            <Form.Item label="关联店铺" name="shopId" rules={[{ required: true, message: '请选择小程序关联的店铺' }]}>
                <Select onChange={onShopChange} options={shopListList} disabled />
            </Form.Item>

            <Form.Item label="绑定者姓名" name="binderName" rules={[{ required: true, message: '请填写绑定者姓名' }]} >
                <Input disabled />
            </Form.Item>

            <Form.Item label="绑定者身份证" name="binderIdcard" rules={[{ required: true, message: '请填写绑定者身份证号' }]}>
                <Input disabled />
            </Form.Item>

            <Form.Item label="小程序ID" name="appId" rules={[{ required: true, message: '请输入小程序ID' }]}>
                <Input disabled={editRecord.miniProgramId} />
            </Form.Item>

            <Form.Item label="小程序密钥" name="appSecret" rules={[{ required: true, message: '请输入小程序密钥' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="小程序名称" name="name" rules={[{ required: true, message: '请输入小程序名称' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="小程序密码" name="password" rules={[{ required: true, message: '请输入小程序密码' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="管理员手机号" name="adminPhone" rules={[{ required: true, message: '请输入管理员手机号' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="管理员微信号" name="adminWechat" rules={[{ required: true, message: '请输入管理员微信号' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="管理员邮箱" name="email" rules={[{ required: true, message: '请输入关联邮箱' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="邮箱密码" name="emailPassword" rules={[{ required: true, message: '请输入邮箱密码' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="小程序类型" name="type" rules={[{ required: true, message: '请输入小程序类型' }]}>
                <Radio.Group
                    options={[{ label: '个人', value: 'Individual' }, { label: '个体户', value: 'SelfEmployed' }, { label: '企业', value: 'Business' }]}
                    defaultValue={'Individual'}
                    optionType="button"
                />
            </Form.Item>

            <Form.Item label="状态" name="status" rules={[{ required: true, message: '请输入状态' }]}>
                <Radio.Group
                    options={[{ label: '使用中', value: 'Valid' }, { label: '已停用', value: 'Invalid' }]}
                    defaultValue="Valid"
                    optionType="button"
                />
            </Form.Item>

            <Form.Item label="备注" name="extInfo">
                <Input.TextArea />
            </Form.Item>

        </Form>
    </Drawer>)
}

export default EditModal;