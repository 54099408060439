import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import SearchWrap from './../../common/SearchWrap';
import { TableColAction, TableWrap, TableHead } from './../../common/Table';
import EditDrawer from './EditDrawer';
import './style.scss';

import config from './config';
import columns from './columns';

const MemberList = ({
    FetchApi,
}) => {
    // 抽屉状态
    const [visible, setVisible] = useState(false);
    // 编辑数据，暂存
    const [editRecord, setEditRecord] = useState({});

    // 表格数据
    const [tableInfo, setTableInfo] = useState({
        dataList: [],
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    });

    // 获取合作商列表
    const getCardsList = async (payload) => {
        const reslut = await FetchApi('get:cardTemplate', payload, '加载成功');
        setTableInfo({
            dataList: reslut?.cardTemplateInfoList || [],
            total: reslut?.total || 0,
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize,
        })
    }

    // 分页变动，重新获取数据
    const onPaginationChange = (record) => {
        getCardsList({ pageSize: record.pageSize, pageNumber: record.pageNumber });
    }

    // 新增/编辑 信息，record 有值为新增
    const onEditFn = (record = {}) => {
        setVisible(true);
        setEditRecord(record);
    }

    // 删除数据
    const onDeleteFn = (record) => {
        console.log('删除', record)
    }

    // 关闭抽屉
    const onOkFn = () => {
        onCloseFn();
    }

    // 关闭抽屉
    const onCloseFn = () => {
        setVisible(false);
    }

    const onSearch = (params) => {
        getCardsList({ pageSize: 10, pageNumber: 1, ...params });
    }

    const onReset = () => {
        getCardsList({ pageSize: 10, pageNumber: 1 });
    }

    // 初始化页面
    useEffect(() => {
        getCardsList({ pageSize: 10, pageNumber: 1 });
    }, [])

    return <div className='member-card-page'>
        {/* 搜索组件 */}
        <SearchWrap config={config} onSearch={onSearch} onReset={onReset} />

        {/* 表头 */}
        <TableHead>
            <Button type="primary" onClick={() => onEditFn({})}>新增</Button>
        </TableHead>

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo.dataList}
            columns={columns}
            total={tableInfo.total}
            pageNumber={tableInfo.pageNumber}
            pageSize={tableInfo.pageSize}
            onPaginationChange={onPaginationChange}
        >
            <TableColAction text='编辑' onClick={(record) => onEditFn(record)} />
            {/* <TableColAction text='删除' isTip onClick={onDeleteFn} editRecord={editRecord} /> */}
        </TableWrap>

        <EditDrawer
            FetchApi={FetchApi}
            visible={visible}
            editRecord={editRecord}
            onOk={onOkFn}
            onClose={onCloseFn}
            getCardsList={getCardsList}
        />
    </div>
}

export default MemberList;