import React, { useState, useEffect } from 'react';
import SearchWrap from './../../common/SearchWrap';
import { TableColAction, TableWrap } from './../../common/Table';
import EditDrawer from './EditDrawer';
import ModalCharge from './ModalCharge';
import { Modal } from 'antd';
import './style.scss';

import searchConfig from './config';
import columns from './columns';
import { click } from '@testing-library/user-event/dist/click';

const MemberList = ({
    FetchApi,
}) => {

    // 是否弹出会员
    const [chargeVisible, setChargeVisible] = useState(false);
    // 是否弹出会员
    const [filterData, setFilterData] = useState({});
    // 会员详情
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userDetailList, setDetailList] = useState({})
    // 新增或编辑面板的显示状态
    const [visible, setVisible] = useState(false);
    // 编辑暂存数据
    const [editRecord, setEditRecord] = useState({});
    // 表格数据
    const [tableInfo, setTableInfo] = useState({
        dataList: [],
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    });

    /**
     * 获取合作商列表
     */
    const getUserList = async (payload) => {
        const reslut = await FetchApi('get:user', {
            ...filterData,
            pageSize: tableInfo.pageSize,
            pageNumber: tableInfo.pageNumber,
            ...payload
        }, '加载成功');

        if (reslut.success) {
            setTableInfo({
                dataList: reslut?.userInfoList || [],
                total: reslut?.total || 0,
                pageNumber: payload.pageNumber || tableInfo.pageNumber,
                pageSize: payload.pageSize || tableInfo.pageSize,
            })
        }
    }

    /**
     * 会员充值
     */
    const onRecharge = async (payload) => {

        let result = null;
        if (payload.clickType === 'charge') {
            result = await FetchApi(`put:user/recharge/${payload.userId}`, payload, '充值成功');
        } else {
            result = await FetchApi(`put:card/verify/${payload.phone}`, null, '核销成功');
        }

        if (result.success) {
            setChargeVisible(false);
            await getUserList({ pageSize: 10, pageNumber: 1 });
            setEditRecord({})
        }
    }

    const onDeleteFn = (record) => {
        console.log('删除', record)

    }

    // 分页变动，重新获取数据
    const onPaginationChange = (record) => {
        getUserList({ pageSize: record.pageSize, pageNumber: record.pageNumber });
    }

    const onSearch = (params) => {
        setFilterData(params);
        getUserList(params);
    }

    const onReset = () => {
        setFilterData({});
        getUserList({ pageSize: 10, pageNumber: 1 });
    }

    const onEdit = (record) => {
        setVisible(true);
        setEditRecord(record);
    }

    const onShowDetail = (record) => {
        setIsModalOpen(true);
        getUserDetail(record)
    }

    const handleClose = () => {
        setIsModalOpen(false);
    }

    const getUserDetail = async (payload) => {
        const userId = payload?.userId;
        const reslut = await FetchApi(`get:card/${userId}`);

        if (reslut.success) {
            setDetailList({
                userDetailList: reslut?.cardInfo || [],
            })
        }
    }

    // 获取合作商列表
    const editUserInfo = async (payload) => {
        const userId = payload?.userId;
        const reslut = await FetchApi(
            userId ? `put:user/${userId}` : 'post:merchant',
            payload,
            userId ? '修改成功' : '新增成功');

        if (reslut.success) {
            await getUserList({
                pageNumber: 1,
                pageSize: 10,
            });
            setVisible(false);
        }
    }

    const onCharge = (record) => {
        setEditRecord(record)
        setChargeVisible(true)
    }

    const onWriteOffHandle = async (record) => {
        console.log(record)
        const reslut = await FetchApi(`put:user/${record.phone}`);
        if (reslut.success) {
            
        }
    }

    // 初始化页面
    useEffect(() => {
        getUserList({ pageSize: 10, pageNumber: 1 });
    }, [])

    return <>
        {/* 搜索组件 */}
        <SearchWrap config={searchConfig} onSearch={onSearch} onReset={onReset} />

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo.dataList}
            columns={columns}
            total={tableInfo.total}
            pageNumber={tableInfo.pageNumber}
            pageSize={tableInfo.pageSize}
            onPaginationChange={onPaginationChange}
            lastColWidth={250}
            scroll={{ x: '100%' }}
        >
            <TableColAction text='充值' onClick={(record) => onCharge({...record, clickType: 'charge'})} />
            <TableColAction text='核销' onClick={(record) => onCharge({...record, clickType: 'writeOff'})} />
            <TableColAction text='编辑' onClick={onEdit} />
            <TableColAction text='查看' onClick={onShowDetail} />
        </TableWrap>

        {/* 编辑面板 */}
        <EditDrawer
            visible={visible}
            editRecord={editRecord}
            onClose={() => setVisible(false)}
            onOk={editUserInfo}
        />

        {/* 会员充值-弹层 */}

        <ModalCharge
            onRecharge={onRecharge}
            visible={chargeVisible}
            editRecord={editRecord}
            onClose={() => setChargeVisible(false)}
        />
    </>
}

export default MemberList;