import React, { useEffect } from 'react';
import { Form, Select, Input, Drawer, Button, Transfer } from 'antd';

const EditModal = ({
    visible,
    editRecord = {},
    onOk = () => { },
    onClose = () => { },
    statusList = [],
    adminList = [],
}) => {

    // form 实例
    const [form] = Form.useForm();

    useEffect(() => {

        if (editRecord.userId) {
            form.setFieldsValue(editRecord);
        } else {
            form.resetFields();
        }
    }, [editRecord]);

    const onOkFn = () => {

        form.validateFields().then(vals => {
            onOk({ ...editRecord, ...vals });
        }).catch((errorInfo) => {
            console.log('errorInfo', errorInfo);
        });

    }

    return (<Drawer
        title="编辑角色"
        onClose={onClose}
        open={visible}
        width={'40%'}
        footer={<>
            <Button onClick={onOkFn} type='primary'>确定</Button>
            <Button onClick={onClose} style={{ marginLeft: 24 }}>取消</Button>
        </>}
    >
        <Form
            form={form}
            labelAlign="right"
            labelCol={{ flex: '100px' }}
        >
            <Form.Item
                label="名称"
                name="userName"
                required
                rules={[{ required: true, message: '请填写合作商名称' }]}
            >
                <Input maxLength={64} disabled />
            </Form.Item>
            <Form.Item
                label="联系方式"
                name="phone"
                required
                rules={[{ required: true, message: '请填写联系方式' }]}
            >
                <Input maxLength={11} disabled />
            </Form.Item>
            <Form.Item label="姓名" name="userName">
                <Input maxLength={64} disabled />
            </Form.Item>
            <Form.Item label="车牌号" name="licensePlateNumber">
                <Input maxLength={18} disabled />
            </Form.Item>
            <Form.Item label="备注" name="remark">
                <Input maxLength={64} />
            </Form.Item>
        </Form>
    </Drawer>)
}

export default EditModal;