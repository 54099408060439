import React, { useState, useEffect } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import './style.scss';

const ContractFiles = ({
    FetchApi,
}) => {

    const [fileList, setFileList] = useState([
        { id: 1, title: '商家入驻协议', desc: '商家入驻协议商家入驻协议商家入驻协议商家入驻协议商家入驻协议商家入驻协议商家入驻协议商家入驻协议' },
        { id: 2, title: '用户隐私协议', desc: '用户隐私协议用户隐私协议用户隐私协议用户隐私协议用户隐私协议用户隐私协议' },
    ])

    const mdParser = new MarkdownIt(/* Markdown-it options */);

    return (<>
        <div className='contract-files-container'>
            {
                [{ id: 'edit', title: 'XXXXX', desc: 'XXXXXXXXXXXXX' }, ...fileList].map(item => (<div className='contract-card'>
                    <div className='contract-head'>
                        <span className='contract-title'>{item.title}</span>
                        <span className='iconfont icon-maobi' />
                    </div>
                    <div className='contract-desc'>{item.desc}</div>
                </div>))
            }
        </div>

        {/* markdown 编辑器 */}
        {/* <div className='markdown-editor-modal'>
            <MdEditor
                renderHTML={text => { console.log('111111', mdParser.render(text)); return mdParser.render(text) }}
            />
        </div> */}
    </>)
}

ContractFiles.defaultProps = {

}

export default ContractFiles;