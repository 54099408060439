import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';
import ResetPwd from './ResetPwd';
import './style.scss';

export default ({
    FetchApi,
    setLoginInfo,
}) => {

    const pageType = new URLSearchParams(window.location.search).get('pageType')

    const [pageName, setPageName] = useState(pageType);

    return (<div className='login-register-container'>
        {
            {
                login: <Login setPageName={setPageName} FetchApi={FetchApi} setLoginInfo={setLoginInfo} />,
                register: <Register setPageName={setPageName} CountDown={CountDown} FetchApi={FetchApi} />,
                resetPwd: <ResetPwd setPageName={setPageName} CountDown={CountDown} FetchApi={FetchApi} />
            }[pageName || 'login']
        }
    </div>
    )
}


// 倒计时组件
const CountDown = ({ onClick, initNum = 60, disabled = false }) => {
    const [countNum, setCountNum] = useState(0);
    let countNumTimer = setTimeout(() => {
        clearTimeout(countNumTimer);
        if (countNum > 0) {
            setCountNum(countNum - 1);
        }
    }, 1000);

    const verifyPhoneNumber = () => {
        if (countNum > 0 || disabled) return;
        onClick && onClick();
        setCountNum(initNum);
    }

    return (
        <span
            className={`verify-code-btn ${countNum <= 0 && 'btn-style'} ${disabled && 'disabled-verify-btn'}`}
            onClick={verifyPhoneNumber}
        >
            {countNum > 0 ? `剩余${countNum}s` : '验证码'}
        </span>
    );
};
