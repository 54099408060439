import { useState } from 'react';
import { Modal, Input, InputNumber, message } from 'antd';
import './style.scss';


export default ({
    onRecharge,
    visible = {},
    onClose,
    editRecord = {},
}) => {

    // 记录充值次数
    const [chargeNum, setChargeNum] = useState(1);

    const onOk = () => {
        const Reg = new RegExp(/^[1-9]\d*$/)
        if (!Reg.test(chargeNum)) {
            message.error('次数输入内容不合法', 2);
            return false;
        }

        onRecharge({ userId: editRecord.userId, frequency: chargeNum, clickType: editRecord.clickType, phone: editRecord.phone });
    }

    return <Modal
        title={editRecord.clickType === 'charge' ? "会员充值" : "会员核销"}
        open={visible}
        onOk={onOk}
        onCancel={onClose}
        okText="确认"
        cancelText="取消"
        className='charge-modal'
    >

        <div className='modal-user-info'>
            <img src={editRecord.avatarUrl} className='user-img' />
            <div className='user-info'>
                <div className='info-row'>
                    <span className='info-row-key'>姓名：</span>
                    <span className='info-row-val'>{editRecord.licensePlateNumber}</span>
                </div>
                <div className='info-row'>
                    <span className='info-row-key'>手机号：</span>
                    <span className='info-row-val'>{editRecord.phone}</span>
                </div>
                <div className='info-row'>
                    <span className='info-row-key'>车牌号：</span>
                    <div className='info-row-val'>
                        <span>{editRecord.licensePlateNumber}</span>
                        <div className='frequency-wrap'>
                            <span>剩余</span>
                            <span>{editRecord.frequency}</span>
                            <span>数</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
{
    editRecord.clickType === 'charge' &&
        <InputNumber
            min={1}
            max={10}
            placeholder='请输入充值次数（正整数）'
            addonBefore={`给${editRecord.userName}充值：`}
            addonAfter="次"
            defaultValue={1}
            onChange={(val) => setChargeNum(val)}
            style={{ width: '100%' }}
        />
}
        
        <div className='modal-tip'>
            {
                editRecord.clickType === 'charge' ? '注意：充值前，请确定会员已经完成付款，避免造成您的资产损失' : '注意：核销后，请确定会员剩余次数，避免造成您的资产损失'
            }
        </div>
    </Modal>
}