import moment from 'moment';
import { Tag, Image } from 'antd';
import { render } from '@testing-library/react';

const STATUS_INFO = {
    Init: {
        text: '待签约',
        color: 'orange',
    },
    Waiting: {
        text: '配置中',
        color: 'yellow',
    },
    Valid: {
        text: '合作中',
        color: 'green',
    },
    Invalid: {
        text: '解约',
        color: 'red',
    },
}
const roleTypeMap = {
    'SuperAdmin': '超级管理员',
    'Admin': '管理员',
    'Partner': '合作商'
}

export default ({
    
}) => {
    return [
        {
            title: '角色类型',
            dataIndex: 'roleType',
            render: val => roleTypeMap[val],
            width: 120,
        },
        {
            title: '品牌名称',
            dataIndex: 'brandName',
            width: 180,
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 120,
        },
        {
            title: '姓名',
            dataIndex: 'idcard',
            width: 180,
        },
        {
            title: '身份证正面',
            dataIndex: 'idcardFrontUrl',
            render: (val) => (<Image src={val} style={{ width: 30, height: 30, display: 'block'}}/>),
            width: 120,
        },
        {
            title: '身份证反面',
            dataIndex: 'idcardBackUrl',
            render: (val) => (<Image src={val} style={{ width: 30, height: 30, display: 'block'}}/>),
            width: 120,
        },
        {
            title: '营业执照',
            dataIndex: 'businessLicenseUrl',
            render: (val) => (<Image src={val} style={{ width: 30, height: 30}}/>),
            width: 180,
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: 150,
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            width: 180,
        },
        {
            title: '页面权限',
            dataIndex: 'config',
            render: configList => configList?.map(i => <Tag key={i}>{i}</Tag>),
            width: 180,
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            render: val => moment(val).format('YYYY-MM-DD HH:mm:ss'),
            width: 180,
        },
        {
            title: '修改时间',
            dataIndex: 'gmtModify',
            render: val => moment(val).format('YYYY-MM-DD HH:mm:ss'),
            width: 180,
        },
        {
            title: '合作状态',
            dataIndex: 'status',
            render: (val) => (<Tag key={val} color={STATUS_INFO?.[val]?.color}>{STATUS_INFO?.[val]?.text}</Tag>),
            width: 120,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 160,
        }
    ]
} 