import React, { useState } from 'react';
import './style.scss';

const Dataview = ({ loginInfo = {} }) => {


    const appStatus = {
        'Init': '待签约',
        'Valid': '合作中',
        'Invalid': '已解约'
    }

    return <div className='status-page-container'>

        <div className='status-info-wrap'>
            <span className='iconfont icon-loading' />
            <span>{appStatus[loginInfo?.status] || '未知状态'}</span>
            <div className='status-title'>商家入驻完成，小二审核中</div>
            <div>审核通过即可获得店铺小程序的相关信息</div>
        </div>

    </div>
}

export default Dataview;