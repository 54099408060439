import { Tag } from 'antd';

const STATUS_INFO = {
    Valid: {
        text: '使用中',
        color: 'green',
    },
    Invalid: {
        text: '已停用',
        color: 'red',
    },
}

const TYPES = {
    'Individual': '个人',
    'SelfEmployed': '个体户',
    'Business': '企业'
}

export default [
    {
        title: '小程序名称',
        dataIndex: 'name',
        fixed: 'left',
        width: 160,
    },
    {
        title: '小程序码',
        dataIndex: 'appOssUrl',
        width: 160,
        render: val => (<img src={val} style={{
            width: 80,
            height: 80,
            borderRadius: 6,
            display: 'block',
            border: '1px solid #ddd'
        }} />)
    },
    {
        title: '小程序ID',
        dataIndex: 'appId',
        width: 120,
    },
    {
        title: '小程序密钥',
        dataIndex: 'appSecret',
        width: 160,
    },
    {
        title: '小程序密码',
        dataIndex: 'password',
        width: 110,
    },
    {
        title: '关联邮箱',
        dataIndex: 'email',
        width: 160,
    },
    {
        title: '关联邮箱密码',
        dataIndex: 'emailPassword',
        width: 160,
    },
    {
        title: '绑定者姓名',
        dataIndex: 'binderName',
        width: 110,
    },
    {
        title: '绑定者身份证',
        dataIndex: 'binderIdcard',
        width: 180,
    },
    {
        title: '管理员手机号',
        dataIndex: 'adminPhone',
        width: 130,
    },
    {
        title: '管理员微信号',
        dataIndex: 'adminWechat',
        width: 120,
    },
    {
        title: '类型',
        dataIndex: 'type',
        width: 80,
        render: (val) => (TYPES[val] || '-')
    },
    {
        title: '备注',
        width: 220,
        dataIndex: 'extInfo',
    }, {
        title: '状态',
        dataIndex: 'status',
        width: 80,
        fixed: 'right',
        render: (val) => (<Tag key={val} color={STATUS_INFO?.[val]?.color}>{STATUS_INFO?.[val]?.text}</Tag>)
    }
]