export default [
    {
        id: 'userName',
        label: '姓名',
        formType: 'input',
    },
    {
        id: 'phone',
        label: '电话',
        formType: 'input',
    },
    {
        id: 'gender',
        label: '性别',
        formType: 'radio',
        options: [{ label: '男', value: '1' }, { label: '女', value: '2' }, { label: '未知', value: '0' }]
    },
    {
        id: 'licensePlateNumber',
        label: '车牌号',
        formType: 'input',
    },
    // {
    //     id: 'area',
    //     label: '区域',
    //     formType: 'input',
    // },
    // {
    //     id: 'createTime',
    //     label: '创建时间',
    //     formType: 'input',
    // }
]