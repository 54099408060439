const config = [{
    id: 'input',
    label: '名称',
    formType: 'input',
}, {
    id: 'radio',
    label: '性别',
    formType: 'radio',
    options: [{ label: '男', value: 'female' }, { label: '女', value: 'male' }]
}, {
    id: 'select',
    label: '星座',
    formType: 'select',
    options: [{ label: '天蝎', value: '1' }, { label: '水平', value: '2' }, { label: '射手', value: '3' }]
}, {
    id: 'datePicker',
    label: '生日',
    formType: 'datePicker',
}, {
    id: 'rangePicker',
    label: '时间区间',
    formType: 'rangePicker',
}]

export default config;