
import { useState } from 'react';
import React from 'react';
import { Button, Form, Input, Radio, Select, Cascader, DatePicker } from 'antd';
import defaultConfig from './config';
import './style.scss';

const SearchWrap = ({
    config = defaultConfig,
    onSearch,
    onReset
}) => {
    // 是否展示更多表单项
    const [visible, setVisible] = useState(false);
    // form 实例
    const [formInstance] = Form.useForm();

    // 筛选
    const onSearchFn = () => {
        const vals = formInstance.getFieldsValue();
        console.log('form-search-vals-->', vals);
        onSearch && onSearch(vals);
    }
    // 重置
    const onResetFn = () => {
        formInstance.resetFields();
        console.log('form-reset');
        onReset && onReset(formInstance);
    }

    return (<div className="search-wrap">
        <Form
            className="search-form-wrap"
            layout="vertical"
            form={formInstance}
        >
            {
                config.map(({ formType, config = {}, label = '示例标题', style, ...item }, idx) => (
                    <Form.Item
                        className="search-form-item"
                        label={label}
                        key={item.id}
                        name={item.id}
                        style={{ ...style, display: idx > 2 && !visible ? 'none' : 'block' }}
                    >
                        {
                            {
                                input: <Input {...item} />,
                                select: <Select {...item} />,
                                cascader: <Cascader {...item} />,
                                datePicker: <DatePicker {...item} />,
                                rangePicker: <DatePicker.RangePicker {...item} />,
                                radio: <Radio.Group {...item} optionType="button" />,
                            }[formType || 'input']
                        }
                    </Form.Item>
                ))
            }
        </Form>
        <div className="search-btns-wrap">
            <Button type="primary" className="search-confirm-btn" onClick={onSearchFn}>搜索</Button>
            <Button type="primary" ghost className="search-reset-btn" onClick={onResetFn}>重置</Button>
            {
                config.length > 3 && <Button type="text" className="show-more-btn" onClick={() => setVisible(!visible)}>{visible ? '收起' : '更多'}</Button>
            }
        </div>
    </div>);
}


export default SearchWrap;