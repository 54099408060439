import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import SearchWrap from '../../common/SearchWrap';
import { TableColAction, TableWrap, TableHead } from '../../common/Table';
import EditModal from './EditDrawer';
import './style.scss';

import config from './config';
import columns from './columns';

const MiniAppList = ({
    FetchApi
}) => {

    // 编辑面板状态
    const [visible, setVisible] = useState(false);
    // 编辑数据
    const [editRecord, setEditRecord] = useState({});
    // 编辑数据
    const [tableInfo, setTableInfo] = useState({
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        dataList: [],
    });

    const getMiniAppList = async (payload) => {
        const result = await FetchApi('get:wechatMiniProgram', {
            pageNumber: payload.pageNumber || tableInfo.pageNumber,
            pageSize: payload.pageSize || tableInfo.pageSize,
            ...payload
        }, '加载完成')

        if (result.success) {
            setTableInfo({
                dataList: result?.infoList || [],
                pageNumber: payload.pageNumber || tableInfo.pageNumber,
                pageSize: payload.pageSize || tableInfo.pageSize,
                total: result.total
            })
        }
    }

    const createMiniApp = async (payload) => {
        const result = await FetchApi('post:wechatMiniProgram', payload, '新建成功');
        return result;
    }

    const editMiniApp = async (payload) => {
        const result = await FetchApi(`put:wechatMiniProgram/${payload.miniProgramId}`,
            payload, '编辑成功')
        return result;
    }

    const onOkFn = async (record) => {

        let result = {};

        if (record.miniProgramId) {
            result = await editMiniApp(record);
        } else {
            result = await createMiniApp(record);
        }

        if (result?.success) {
            await getMiniAppList({ pageSize: 10, pageNumber: 1 })
        }
    }

    useEffect(() => {
        getMiniAppList({ pageSize: 10, pageNumber: 1 })
    }, [])

    // 分页调用
    const onPaginationChange = async (vals) => {
        await getMiniAppList({ ...vals });
    }

    // 新增/编辑方法
    const onEditFn = (record) => {
        console.log('编辑', record)
        setVisible(true);
        setEditRecord(record);
    }

    // 删除方法
    const onDeleteFn = (record) => {
        console.log('删除', record)
    }

    const onSearch = (params) => {
        getMiniAppList({ pageSize: 10, pageNumber: 1, ...params });
    }
    const onReset = () => {
        getMiniAppList({ pageSize: 10, pageNumber: 1 });
    }

    return <>
        {/* 搜索组件 */}
        <SearchWrap config={config} onSearch={onSearch} onReset={onReset} />

        {/* 表头 */}
        <TableHead>
            <Button type="primary" onClick={() => onEditFn({})}>新增</Button>
        </TableHead>

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo?.dataList || []}
            scroll={{ x: '100%' }}
            columns={columns}
            lastColWidth={80}
            total={tableInfo.total}
            pageNumber={tableInfo.pageNumber}
            pageSize={tableInfo.pageSize}
            onPaginationChange={onPaginationChange}
        >
            <TableColAction text='编辑' onClick={onEditFn} />
        </TableWrap >

        <EditModal
            visible={visible}
            onClose={() => setVisible(false)}
            editRecord={editRecord}
            onOk={onOkFn}
            FetchApi={FetchApi}
        />
    </>
}

export default MiniAppList;