export default [
    {
        id: 'templateId',
        label: 'ID',
        formType: 'input',
    },
    {
        id: 'templateName',
        label: '名称',
        formType: 'input',
    },
    // {
    //     id: 'status',
    //     label: '状态',
    //     formType: 'input',
    // },
    {
        id: 'status',
        label: '状态',
        formType: 'select',
        options: [{ label: '待生效', value: 'Init' }, { label: '生效中', value: 'Valid' }, { label: '已失效', value: 'Invalid' }]
    }
]