const columns = [{
    title: 'ID',
    dataIndex: 'id',
}, {
    title: '标题',
    dataIndex: 'title',
}, {
    title: '年龄',
    dataIndex: 'age',
}, {
    title: '性别',
    dataIndex: 'gender',
}, {
    title: '联系方式',
    dataIndex: 'phone',
}];

export default columns;