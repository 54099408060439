import { BrowserRouter, HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import UserCenter from './pages/UserCenter';
import MemberCard from './pages/MemberCard';
import MemberList from './pages/MemberList';
import ConfigList from './pages/ConfigList';
import MiniAppList from './pages/MiniAppList';
import LoginWrap from './pages/LoginWrap';
import ContractFiles from './pages/ContractFiles';
import PageWrap from './pages';
import FetchApi from './utils/request';
import { useEffect, useState } from 'react';

const AppRouter = () => {

    let userInfo = {};
    try {
        userInfo = JSON.parse(sessionStorage.getItem('loginInfo')) || {};
    } catch (err) {
        userInfo = {};
    }

    // 是否登录
    const [loginInfo, setLoginInfo] = useState(userInfo);

    const getShopListByMerchantId = async () => {
        if (!userInfo?.merchantId) {
            return;
        }
        const reslut = await FetchApi(`get:merchant/${userInfo.merchantId}`, {});
        setLoginInfo(reslut?.merchantInfo);
        sessionStorage.setItem('loginInfo', JSON.stringify(reslut?.merchantInfo));

    }

    useEffect(() => {
        getShopListByMerchantId()
    }, [])

    return (<HashRouter>
        <Routes>
            <Route path={"/"} element={<PageWrap loginInfo={loginInfo} ReactNode={<UserCenter FetchApi={FetchApi} />} />} />

            {
                userInfo?.roleType === 'Admin' && 
                <>
                    <Route path={"/miniapp-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<MiniAppList FetchApi={FetchApi} />} />} />
                    <Route path={"/config-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<ConfigList FetchApi={FetchApi} />} />} />
                    <Route path={"/contract-files"} element={<PageWrap loginInfo={loginInfo} ReactNode={<ContractFiles FetchApi={FetchApi} />} />} />
                </>
            }
            
            <Route path={"/member-card"} element={<PageWrap loginInfo={loginInfo} ReactNode={<MemberCard FetchApi={FetchApi} />} />} />
            <Route path={"/member-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<MemberList FetchApi={FetchApi} />} />} />

            {/* 登录页面 */}
            <Route path={"/login"} element={<LoginWrap FetchApi={FetchApi} setLoginInfo={setLoginInfo} />} />
            {/* 未匹配到任何路由时跳转到首页 */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </HashRouter>)
}

export default AppRouter;