import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Drawer, Button, Transfer, Checkbox } from 'antd';

const EditModal = ({
    visible,
    editRecord = {},
    onOk = () => { },
    onClose = () => { },
    statusList = [],
    adminList = [],
}) => {

    const pagesRoleOptions = [
        // { label: '小程序配置', value: 1 },
        { label: '卡模板', value: 2 },
        // { label: '商品列表', value: 3 },
        { label: '会员列表', value: 4 }
    ];

    // form 实例
    const [form] = Form.useForm();

    const [pagesRole, setPagesRole] = useState({});

    useEffect(() => {
        if (editRecord.merchantId) {
            form.setFieldsValue(editRecord);
        } else {
            form.resetFields();
        }
    }, [editRecord]);

    const onOkFn = () => {

        form.validateFields().then(vals => {
            onOk({ ...editRecord, ...vals });
        }).catch((errorInfo) => {
            console.log('errorInfo', errorInfo);
        });

    }

    const onPagesRoleChange = (checkedValues) => {
        console.error('checkedValues', checkedValues)
        setPagesRole(checkedValues);
    }

    return (<Drawer
        title={Object.keys(editRecord).length ? "编辑商家" : '新增商家'}
        onClose={onClose}
        open={visible}
        width={'40%'}
        footer={<>
            <Button onClick={onOkFn} type='primary'>确定</Button>
            <Button onClick={onClose} style={{ marginLeft: 24 }}>取消</Button>
        </>}
    >
        <Form
            form={form}
            labelAlign="right"
            labelCol={{ flex: '80px' }}
        >
            <Form.Item
                label="角色类型"
                name="roleType"
                required
                rules={[{ required: true, message: '请选择角色类型' }]}
            >
                <Select options={adminList} disabled={editRecord.merchantId} />
            </Form.Item>
            <Form.Item
                label="商户名称"
                name="brandName"
                required
                rules={[{ required: true, message: '请填写作商名称' }]}
            >
                <Input maxLength={64} disabled={editRecord.merchantId} />
            </Form.Item>
            <Form.Item label="姓名" name="name">
                <Input maxLength={64} disabled={editRecord.merchantId} />
            </Form.Item>
            <Form.Item label="身份证" name="idcard">
                <Input maxLength={18} disabled={editRecord.merchantId} />
            </Form.Item>
            <Form.Item label="邮箱" name="email">
                <Input maxLength={18} disabled={editRecord.merchantId} />
            </Form.Item>
            <Form.Item
                label="商家手机号"
                name="phone"
                required
                rules={[{ required: true, message: '请填写商家手机号' }]}
            >
                <Input maxLength={11} disabled={editRecord.merchantId} />
            </Form.Item>
            <Form.Item
                label="合作状态"
                name="status"
                required
                rules={[{ required: true, message: '请选择状态' }]}
            >
                <Select options={statusList} />
            </Form.Item>
            <Form.Item label="权限分配" name="config" >
                {/* <Transfer
                    dataSource={[
                        { title: '小程序配置', key: 1 },
                        { title: '会员卡', key: 2 },
                        { title: '商品列表', key: 3 },
                        { title: '会员列表', key: 4 }
                    ]}
                    titles={['未有', '已有']}
                    render={(item) => item.title}
                /> */}
                <Checkbox.Group options={pagesRoleOptions} onChange={onPagesRoleChange}>

                </Checkbox.Group>
            </Form.Item>
            <Form.Item label="备注" name="extInfo">
                <Input maxLength={64} />
            </Form.Item>
        </Form>
    </Drawer>)
}

export default EditModal;