import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import './style.scss';

const ResetPwd = ({
    setPageName,
    CountDown,
    FetchApi,
}) => {

    // 是否显示明文密码
    const [showPwd, setShowPwd] = useState(false);

    const [form] = Form.useForm();

    // 监听手机号变动
    const phoneNumber = Form.useWatch('phone', form);
    // 校验手机号是否合法
    const isFormatPhone = !new RegExp(/^1[3-9]\d{9}$/).test(phoneNumber);

    // 重置密码
    const onResetPwd = async () => {

        try {
            const vals = await form.validateFields();

            const reslut = await FetchApi('post:auth/modifyPassword', {
                phone: vals.phone,
                password: vals.password,
                verificationCode: vals.verifyCode
            }, '密码已充值');

            if (reslut.success) {
                setTimeout(() => {
                    setPageName('login');
                }, 500)
            }

        } catch (err) {
            console.error(err);
        }

    }

    // 获取验证码
    const onVerify = async () => {

        if (isFormatPhone) {
            console.log('手机号不合法');
            return;
        }

        const tempPhone = form.getFieldValue('phone');
        await FetchApi(`get:auth/sendSms/${tempPhone}`, {
            phone: tempPhone
        }, '验证码已发送');
    }

    // 校验两次密码是否一致
    const diffPwdFn = (diffKey, actVal, callback) => {
        const diffVal = form.getFieldValue(diffKey)
        if (diffVal && actVal !== diffVal) {
            callback(new Error('密码不一致'))
        } else {
            callback()
        }
    }

    // 明文显示密码
    const onToggleShow = () => {
        setShowPwd(!showPwd);
    }

    return (
        <div className='reset-pwd-page-container'>
            <h1 className='reset-pwd-page-title'>重置密码</h1>
            <Form
                className='reset-pwd-page-form-wrap'
                form={form}
            >
                <Form.Item
                    className='login-form-item'
                    name='phone'
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请填写手机号' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式有误' },
                    ]}
                >
                    <Input
                        className='phone-input'
                        placeholder='手机号'
                        maxLength={11}
                        prefix={<i className='iconfont icon-phone' />}
                        suffix={<CountDown
                            initNum={60}
                            onClick={onVerify}
                            disabled={isFormatPhone}
                        />}
                    />
                </Form.Item>
                <Form.Item
                    className='login-form-item'
                    name='verifyCode'
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请填写验证码' },
                        { pattern: /^\d{4}$/, message: '数字验证码格式有误' },
                    ]}
                >
                    <Input
                        placeholder='验证码'
                        maxLength={6}
                        prefix={<i className='iconfont icon-verify' />}
                    />
                </Form.Item>
                <Form.Item
                    className='login-form-item'
                    name='password'
                    validateTrigger="onBlur"
                    dependencies={['rePassword']}
                    rules={[
                        { required: true, message: '请填写新密码' },
                        { pattern: /^[A-Za-z0-9_.@]{6,16}$/, message: '仅支持字母、数字、下划线、点，长度6-16位' },
                        { validator: (rules, val, cb) => diffPwdFn('rePassword', val, cb), trigger: 'blur' },
                    ]}
                >
                    <Input
                        type={showPwd ? 'text' : 'password'}
                        prefix={<i className='iconfont icon-password' />}
                        suffix={<i className={`iconfont ${showPwd ? 'icon-eye' : 'icon-hide-eye'}`} onClick={onToggleShow} />}
                        placeholder='新密码'
                    />
                </Form.Item>
                <Form.Item
                    className='login-form-item'
                    name='rePassword'
                    validateTrigger="onBlur"
                    dependencies={['password']}
                    rules={[
                        { required: true, message: '请再次填写新密码' },
                        { pattern: /^[A-Za-z0-9_.@]{6,16}$/, message: '仅支持字母、数字、下划线、点，长度6-16位' },
                        { validator: (rules, val, cb) => diffPwdFn('password', val, cb), trigger: 'blur' },
                    ]}
                >
                    <Input
                        type={showPwd ? 'text' : 'password'}
                        prefix={<i className='iconfont icon-password' />}
                        suffix={<i className={`iconfont ${showPwd ? 'icon-eye' : 'icon-hide-eye'}`} onClick={onToggleShow} />}
                        placeholder='确认新密码'
                    />
                </Form.Item>
            </Form>
            <div className='reset-pwd-btns-wrap'>
                <Button className='reset-pwd-btn' type='primary' onClick={onResetPwd}>立即重置</Button>
                <span className='reset-pwd-other-btn' onClick={() => setPageName('register')}>立即注册</span>
                <span className='reset-pwd-other-btn' onClick={() => setPageName('login')} >返回登录</span>
            </div>
        </div>
    )
}

export default ResetPwd;