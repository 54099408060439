import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import SakaCloudMiniappQrcode from './../../../statics/miniapp.jpg'
import './style.scss';


const RegisterPage = ({
    setPageName,
    CountDown,
}) => {

    const [isHide, setIsHide] = useState(true);

    const [form] = Form.useForm();

    const onRegister = async () => {

        let vals = {};

        try {
            vals = await form.validateFields();
            console.log(vals);
        } catch (err) {
            console.error(err);
        }

    }

    const onToggleShow = () => {
        setIsHide(!isHide);
    }

    const onVerify = () => {

    }


    return (
        <div className='register-page-container'>
            <h1 className='register-page-title'>注册账号</h1>

            <div className='qrcode-img'>
                <img className='qrcode' src={SakaCloudMiniappQrcode} />
                {/* <div>请使用微信扫码二维码注册</div> */}
            </div>

            <div className='register-btns-wrap'>
                <span className='register-other-btn' onClick={() => setPageName('resetPwd')}>找回密码</span>
                <span className='register-other-btn' onClick={() => setPageName('login')} >返回登录</span>
            </div>
        </div>
    )
}

export default RegisterPage;