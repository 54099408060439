export default ({
    statusList = [],
    adminList = []
}) => {
    return [
        {
            id: 'roleType',
            label: '角色类型',
            options: adminList,
            formType: 'select',
        },
        {
            id: 'name',
            label: '名称',
            formType: 'input',
        },
        {
            id: 'phone',
            label: '电话',
            formType: 'input',
        },
        {
            id: 'status',
            label: '状态',
            options: statusList,
            formType: 'select',
        }
    ]
}