export default [
    {
        title: '模板ID',
        dataIndex: 'templateId',
    },
    {
        title: '模板名称',
        dataIndex: 'templateName',
    },
    {
        title: '售价',
        dataIndex: 'amount',
    },
    {
        title: '总次数',
        dataIndex: 'frequency',
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModify',
    },
    {
        title: '备注',
        dataIndex: 'extInfo',
    },
    {
        title: '状态',
        dataIndex: 'status',
        render: (val, record) => ({ 'Valid': '生效中', 'Invalid': '已失效', 'Init': '待生效' }[val])
    }
]